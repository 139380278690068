import {
  Box,
  Button,
  Icon,
  Image,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  PopoverArrow,
  Flex,
  Text,
  Divider,
  VStack,
  SimpleGrid,
  Drawer,
  DrawerBody,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  useDisclosure,
  ButtonProps,
  FlexProps,
} from "@chakra-ui/react";
import dayjs from "dayjs";
import { zhTW, enUS, zhCN } from "date-fns/locale/index.js";
import { DateRange } from "react-date-range";
import { addDays } from "date-fns";
import { FC, useState, Dispatch, useCallback, Key } from "react";
import { ModelTypes } from "~/zeus";
import { useGlobalContext } from "~/context/global";
import { useTranslation } from "~/libs/i18n";
import { useParams } from "@remix-run/react";
import { BiMapPin, BiChevronDown } from "react-icons/bi";
import CustomImage from "~/components/CustomImage";

interface CriteriaState {
  [key: string]: any;
}

interface CombineOptions {
  value: string;
  name: string;
  children?: any;
  icon?: any;
}

const getDistricts = (options: any, type: string) => {
  if (!options || type !== "district") {
    return [];
  }

  return options?.map((option: any) => option?.children)?.flat();
};

const ResetButton: FC<{ onClick?: React.MouseEventHandler<HTMLElement> }> = ({
  onClick,
}) => {
  const t = useTranslation();
  return (
    <Flex
      pos={"absolute"}
      right={0}
      top={"2px"}
      gap={1}
      as="button"
      alignItems={"center"}
      onClick={onClick}
    >
      <CustomImage src={"/assets/images/common/delete.svg"} w={4} />
      <Text fontSize={"sm"} color="brand.grey">
        {t("filters.reset")}
      </Text>
    </Flex>
  );
};

const Display: FC<{
  combineOptions: CombineOptions[];
  handleSelect: Function;
  onClose: Function;
  type: string;
  selectedCriteria: string;
  allCriteriaState: CriteriaState;
}> = ({
  type,
  handleSelect,
  onClose,
  selectedCriteria,
  combineOptions,
  allCriteriaState,
}) => {
  let display;
  const t = useTranslation();
  const [showDateRang, setShowDateRang] = useState<boolean>(false);
  const PopoverTitleRow: FC<{
    icon: any;
    title: string;
    url?: string;
  }> = ({ icon, url, title }) => {
    return (
      <Flex
        textAlign={"center"}
        pb={6}
        justifyContent="center"
        alignItems="center"
        gap={1}
        px={12}
      >
        {url ? (
          <CustomImage src={url} alt="icon" w={4} />
        ) : (
          <Icon mt={0} as={icon} />
        )}
        <Text fontSize={"md"}>{title}</Text>
      </Flex>
    );
  };

  const DateRangeComps: FC<{
    handleSelect: Function;
    onClose: any;
    value: string;
    name: string;
    selectedCriteria?: any;
  }> = useCallback(
    ({ handleSelect, onClose, name }) => {
      const { locale } = useParams();
      let dateRangeLocale;
      switch (locale) {
        case "zh":
          dateRangeLocale = zhTW;
          break;
        case "cn":
          dateRangeLocale = zhCN;
          break;
        default:
          dateRangeLocale = enUS;
      }

      const [customTimeValue, setCustomTimeValue] = useState([
        {
          startDate:
            allCriteriaState?.time && allCriteriaState?.time !== "custom-date"
              ? new Date(allCriteriaState?.time?.split(",")?.[0])
              : new Date(),
          endDate:
            allCriteriaState?.time && allCriteriaState?.time !== "custom-date"
              ? new Date(allCriteriaState?.time?.split(",")?.[1])
              : addDays(new Date(), 7),
          key: "selection",
        },
      ]);

      const handleDatePickerOnChange = (ranges: { selection: any }) => {
        const { selection } = ranges;
        setCustomTimeValue([selection]);
      };

      return (
        <Flex direction="column">
          <DateRange
            editableDateInputs={true}
            onChange={(ranges: any) => handleDatePickerOnChange(ranges)}
            moveRangeOnFirstSelection={false}
            ranges={customTimeValue}
            showMonthAndYearPickers={false}
            showDateDisplay={false}
            locale={dateRangeLocale}
            minDate={dayjs()?.toDate()}
          />
          <Box alignSelf={"flex-end"}>
            <Button
              cursor={"pointer"}
              fontSize={["sm"]}
              onClick={() => {
                handleSelect(
                  `${dayjs(customTimeValue?.[0]?.startDate).format(
                    "YYYY-MM-DD"
                  )},${dayjs(customTimeValue?.[0]?.endDate).format(
                    "YYYY-MM-DD"
                  )}`,
                  onClose
                );
                setShowDateRang(false);
              }}
            >
              {t("filters.confirm")}
            </Button>
          </Box>
        </Flex>
      );
    },
    [selectedCriteria]
  );

  switch (type) {
    case "time":
      display = (
        <>
          <Box pos={"relative"}>
            <PopoverTitleRow
              icon={BiMapPin}
              url={"/assets/images/common/time_icon.svg"}
              title={t("filters.whenDoYouWantToGo")}
            />
            <ResetButton onClick={() => handleSelect("", onClose)} />
          </Box>
          <Flex direction={"column"} gap={4} w="full">
            {combineOptions?.map(({ value, name }) => {
              if (value === "past") {
                return null;
              }
              if (value === "custom-date") {
                return (
                  <Flex
                    direction={"column"}
                    p={4}
                    w="full"
                    boxShadow={"md"}
                    rounded={"lg"}
                    justifyContent="space-between"
                    key={value}
                    color={
                      selectedCriteria === value ||
                      (selectedCriteria?.includes(",") &&
                        value === "custom-date")
                        ? "brand.timable-yellow"
                        : "black"
                    }
                    cursor="pointer"
                    _hover={{ color: "brand.timable-yellow" }}
                    bgColor={"#FFF"}
                  >
                    <Box
                      onClick={() => {
                        handleSelect(value, onClose, false);
                        setShowDateRang(!showDateRang);
                      }}
                      cursor="pointer"
                    >
                      <Text fontSize={"md"}>{name}</Text>
                    </Box>
                    {(showDateRang ||
                      (selectedCriteria?.includes(",") &&
                        value === "custom-date")) && (
                      <DateRangeComps
                        handleSelect={handleSelect}
                        value={value}
                        name={name}
                        onClose={onClose}
                        selectedCriteria={selectedCriteria}
                      />
                    )}
                  </Flex>
                );
              }
              return (
                <Flex
                  p={4}
                  w="full"
                  boxShadow={"md"}
                  rounded={"lg"}
                  justifyContent="space-between"
                  key={value}
                  onClick={() => handleSelect(value, onClose)}
                  color={
                    selectedCriteria === value
                      ? "brand.timable-yellow"
                      : "black"
                  }
                  cursor="pointer"
                  _hover={{ color: "brand.timable-yellow" }}
                  bgColor={"#fff"}
                >
                  <Text fontSize={"md"}>{name}</Text>
                </Flex>
              );
            })}
          </Flex>
        </>
      );
      break;
    case "district":
      display = (
        <>
          <Box pos={"relative"}>
            <PopoverTitleRow
              icon={BiMapPin}
              title={t("filters.whereDoYouWantToGo")}
              url={"/assets/images/common/place_icon.svg"}
            />
            <ResetButton onClick={() => handleSelect("", onClose)} />
          </Box>
          <Flex direction={"column"}>
            {combineOptions?.map(
              ({ value, name, children }: CombineOptions) => {
                // console.log("children==>", children);

                return (
                  <Flex
                    p={2}
                    w="full"
                    alignItems="center"
                    key={value}
                    color={
                      selectedCriteria === value
                        ? "brand.timable-yellow"
                        : "black"
                    }
                    direction="column"
                    cursor="pointer"
                    _hover={{ color: "brand.timable-yellow" }}
                  >
                    {children && (
                      <Text
                        fontSize={"md"}
                        mb={2}
                        onClick={() => handleSelect(value, onClose)}
                      >
                        {name}
                      </Text>
                    )}

                    {children && <Divider />}

                    {children && (
                      <SimpleGrid columns={[5, 4]}>
                        {children?.map(
                          ({
                            value,
                            name,
                          }: {
                            value: string;
                            name: string;
                          }) => {
                            return (
                              <Box
                                key={value}
                                onClick={() => handleSelect(value, onClose)}
                                color={
                                  selectedCriteria === value
                                    ? "brand.timable-yellow"
                                    : "black"
                                }
                                cursor="pointer"
                                _hover={{ color: "brand.timable-yellow" }}
                                p={2}
                              >
                                <Text
                                  fontSize={"sm"}
                                  fontWeight={400}
                                  textAlign="center"
                                >
                                  {name}
                                </Text>
                              </Box>
                            );
                          }
                        )}
                      </SimpleGrid>
                    )}
                  </Flex>
                );
              }
            )}
          </Flex>
        </>
      );
      break;
    case "audience":
      display = (
        <>
          <Box pos={"relative"}>
            <PopoverTitleRow
              icon={BiMapPin}
              title={t("filters.whoDoYouWantToGoWith")}
              url={"/assets/images/common/audience_icon.svg"}
            />
            <ResetButton onClick={() => handleSelect("", onClose)} />
          </Box>
          <SimpleGrid columns={[3, 3, 4]} spacing={[4]}>
            {combineOptions?.map(
              ({ value, name, icon }: CombineOptions, index: Key) => {
                if (index === 0 && combineOptions?.length > 1) return null;
                return (
                  <Box
                    key={value}
                    onClick={() => handleSelect(value, onClose)}
                    color={
                      selectedCriteria === value
                        ? "brand.timable-yellow"
                        : "black"
                    }
                    cursor="pointer"
                    _hover={{ color: "brand.timable-yellow" }}
                    boxShadow={"md"}
                    p={[4]}
                    rounded={["md"]}
                    textAlign="center"
                    bgColor={"#fff"}
                  >
                    <VStack
                      h="full"
                      alignItems="center"
                      justifyContent="space-around"
                    >
                      {icon?.url && (
                        <CustomImage src={icon?.url as string} w={30} />
                      )}
                      <Text fontSize={"md"}>{name}</Text>
                    </VStack>
                  </Box>
                );
              }
            )}
          </SimpleGrid>
        </>
      );
      break;
    case "category":
      display = (
        <>
          <Box pos={"relative"}>
            <PopoverTitleRow
              icon={BiMapPin}
              title={t("filters.whatDoYouWantToPlay")}
              url={"/assets/images/common/category_icon.svg"}
            />
            <ResetButton onClick={() => handleSelect("", onClose)} />
          </Box>
          <SimpleGrid columns={[3, 3, 3, 6]} spacing={[4]}>
            {combineOptions?.map(
              ({ value, name, icon }: CombineOptions, index: Key) => {
                if (index === 0 && combineOptions?.length > 1) return null;
                return (
                  <Box
                    key={value}
                    onClick={() =>
                      handleSelect(
                        allCriteriaState?.["category"] === value ? "" : value,
                        onClose
                      )
                    }
                    color={
                      selectedCriteria === value
                        ? "brand.timable-yellow"
                        : "black"
                    }
                    cursor="pointer"
                    _hover={{ color: "brand.timable-yellow" }}
                    boxShadow={"md"}
                    rounded={["md"]}
                    px={[4]}
                    textAlign="center"
                    bgColor={"#fff"}
                    maxW={24}
                  >
                    <VStack
                      h="full"
                      alignItems="center"
                      py={[2]}
                      px={{ base: 0, lg: 1 }}
                      justifyContent="space-around"
                    >
                      {icon?.url && (
                        <CustomImage src={icon?.url as string} w={35} />
                      )}
                      <Text fontSize={"md"}>{name}</Text>
                    </VStack>
                  </Box>
                );
              }
            )}
          </SimpleGrid>
        </>
      );
      break;

    default:
      display = <Box></Box>;
      break;
  }

  return <Box>{display}</Box>;
};

const PopoverSelector: FC<{
  options:
    | ModelTypes["Date"][]
    | ModelTypes["Audience"][]
    | ModelTypes["District"][]
    | ModelTypes["EventCategory"][];
  selectedCriteria?: string | undefined;
  allCriteriaState: CriteriaState;
  setAllCriteria: Dispatch<CriteriaState>;
  placeholder: string;
  type: string;
}> = ({
  options = [],
  selectedCriteria,
  allCriteriaState,
  setAllCriteria,
  type,
  placeholder,
}) => {
  const { isDesktop } = useGlobalContext();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const combineOptions = [{ name: placeholder, value: "" }, ...options];
  const optionList = [...combineOptions, ...getDistricts(options, type)];
  const t = useTranslation();
  let selected = optionList.find((option) => {
    return allCriteriaState[type] === option?.value;
  });

  if (type === "time") {
    if (!selected && allCriteriaState?.time) {
      // Custom time selected
      selected = {
        name: t("filters.customTime"),
        value: allCriteriaState?.time,
      };
    }
  }

  const handleSelect = (
    select: string,
    onClose: any,
    closePopup: boolean = true
  ) => {
    setAllCriteria({ ...allCriteriaState, [type]: select });
    if (closePopup) {
      onClose();
    }
  };

  return isDesktop ? (
    <Popover placement="right-start">
      {({ onClose }) => (
        <>
          <PopoverTrigger>
            <Button
              px={4}
              borderRadius={["md"]}
              fontWeight="bold"
              fontSize="xl"
              w={{ base: "fit-content", lg: "full" }}
              color="black"
              h={10}
              rightIcon={<Icon as={BiChevronDown} />}
            >
              {selected?.name ?? placeholder}
            </Button>
          </PopoverTrigger>
          <PopoverContent
            p={2}
            py={4}
            bgColor="brand.light-grey"
            boxShadow={["md"]}
            rounded={"lg"}
            alignContent="center"
            minW={{ base: "100%", lg: "max-content" }}
          >
            <PopoverArrow bgColor="brand.light-grey" />
            <PopoverBody
              w="full"
              px={{ md: 4 }}
              fontSize={["lg"]}
              bgColor="brand.light-grey"
            >
              <Display
                type={type}
                combineOptions={combineOptions as CombineOptions[]}
                onClose={onClose}
                selectedCriteria={selectedCriteria as string}
                allCriteriaState={allCriteriaState}
                handleSelect={handleSelect}
              />
            </PopoverBody>
          </PopoverContent>
        </>
      )}
    </Popover>
  ) : (
    <>
      <Button
        px={4}
        borderRadius={["md"]}
        fontWeight="bold"
        fontSize="xl"
        w="fit-content"
        color="black"
        rightIcon={<Icon as={BiChevronDown} />}
        onClick={onOpen}
      >
        {selected?.name ?? placeholder}
      </Button>
      <Drawer isOpen={isOpen} onClose={onClose} placement="bottom">
        <DrawerOverlay />
        <DrawerContent borderTopRadius={"xl"}>
          <DrawerBody px={6} py={["14px"]} pb={[10]} minH={"50vh"}>
            <Display
              type={type}
              combineOptions={combineOptions as CombineOptions[]}
              onClose={onClose}
              selectedCriteria={selectedCriteria as string}
              handleSelect={handleSelect}
              allCriteriaState={allCriteriaState}
            />
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
};

export default PopoverSelector;
