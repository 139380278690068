import { type FC, Fragment, Suspense, lazy, useCallback } from "react";

import { Skeleton } from "@chakra-ui/react";

import { useLoaderData } from "@remix-run/react";

import type { SectionType } from "./types";

import { type loader } from "~/routes/$region.$locale._main._index";

const ComingWeekEventAndLocationRankingBlock = lazy(
  () => import("./ComingWeekEventAndLocationRankingBlock")
);
const EventRankingBlock = lazy(() => import("./EventRankingBlock"));
const CategoryListBlock = lazy(() => import("./CategoryListBlock"));
const SubscriptionBlock = lazy(() => import("./SubscriptionBlock"));
const OrganizarBlock = lazy(() => import("./OrganizarBlock"));
const LocationRankingBlock = lazy(() => import("./LocationRankingBlock"));
const BlogBlock = lazy(() => import("./BlogBlock"));
const EventTrendBlock = lazy(() => import("./EventTrendBlock"));
const TimableRankingBlock = lazy(() => import("./TimableRankingBlock"));
const EditorChoiceBlock = lazy(() => import("./EditorChoiceBlock"));
const UserPreferenceBlock = lazy(() => import("./UserPreferenceBlock"));

const HomeSectionsRenderer: FC<{}> = ({}) => {
  const { homePageSettings, homeDayList, homeTrendList, homePreferenceList } =
    useLoaderData<typeof loader>();

  const renderComponent = useCallback(
    (section: SectionType) => {
      switch (section.__typename) {
        case "ComingWeekEventAndLocationRankingBlock":
          return (
            <ComingWeekEventAndLocationRankingBlock
              section={section}
              homeDayList={homeDayList as any}
            />
          );
        case "EventRankingBlock":
          return <EventRankingBlock section={section} />;
        case "CategoryListBlock":
          return <CategoryListBlock section={section} />;
        case "SubscriptionBlock":
          return <SubscriptionBlock section={section} />;
        case "OrganizarBlock":
          return <OrganizarBlock section={section} />;
        case "LocationRankingBlock":
          return <LocationRankingBlock section={section} />;
        case "BlogBlock":
          return <BlogBlock section={section} />;
        case "EventTrendBlock":
          return (
            <EventTrendBlock
              section={section}
              homeTrendList={homeTrendList as any}
            />
          );
        case "TimableRankingBlock":
          return <TimableRankingBlock section={section} />;
        case "EditorChoiceBlock":
          return <EditorChoiceBlock section={section} />;
        case "UserPreferenceBlock":
          return (
            <UserPreferenceBlock
              section={section}
              homePreferenceList={homePreferenceList as any}
            />
          );

        default:
          return null;
      }
    },
    [homeDayList, homePreferenceList, homeTrendList]
  );

  return (
    <>
      {homePageSettings?.sections?.map((section, index) => {
        return (
          <Fragment
            key={`${index}-${"title" in section ? section?.title : section?.blockName}`}
          >
            <Suspense fallback={<Skeleton h={300} w="100%" />}>
              {renderComponent(section as SectionType)}
            </Suspense>
          </Fragment>
        );
      })}
    </>
  );
};

export default HomeSectionsRenderer;
