import {
  Box,
  Flex,
  Text,
  VStack,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  Wrap,
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionPanel,
  AccordionItem,
  Button,
  Image,
  StyleProps,
  ResponsiveValue,
  Icon,
  Divider,
  Input,
  InputLeftElement,
  InputGroup,
} from "@chakra-ui/react";
import CustomImage from "~/components/CustomImage";

import dayjs from "dayjs";
import { zhTW, enUS, zhCN } from "date-fns/locale/index.js";
import { DateRange } from "react-date-range";
import { FC, useState, useCallback, useRef } from "react";
import { ModelTypes } from "~/zeus";
import { useGlobalContext } from "~/context/global";
import { useLocaleNavigate, useTranslation } from "~/libs/i18n";
import { addDays } from "date-fns";
import FilterTag from "~/components/FilterTag";
import { AiOutlineSearch } from "react-icons/ai";
import { BiCheckCircle, BiPlusCircle } from "react-icons/bi";
import {
  convertJsonToUrlParams,
  convertStringToTimeString,
} from "~/utils/commons";
import { feeOptions, walkInOptions } from "~/enums/criteria";
import { useParams } from "@remix-run/react";

function toggleValue(arr: string[], value: string, multi?: boolean): string[] {
  const index = arr.indexOf(value);
  const isMulti = multi ?? true;
  if (index !== -1) {
    // Value already exists, remove it from the array
    arr.splice(index, 1);
  } else {
    // Value doesn't exist, add it to the array
    isMulti ? arr.push(value) : (arr = [value]);
  }
  return arr;
}

function getLevel3Districts(districts: any[]): any[] {
  return districts.flatMap((district: any) => {
    if (district.level === 3) {
      return district;
    } else if (district.children) {
      return getLevel3Districts(district.children);
    } else {
      return [];
    }
  });
}

interface CriteriaState {
  [key: string]: any;
}

const initState = {
  time: [],
  audience: [],
  district: [],
  category: [],
  fee: [],
  walkIn: [],
  search: [],
};

const sectionStyle = {
  w: "100%",
  borderRadius: "lg",
  bgColor: "#FFF",
  boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
  p: 4,
  direction: "column" as ResponsiveValue<"column" | "row">,
  gap: 2,
};

const accordionItemStyle = {
  direction: "row" as ResponsiveValue<"column" | "row">,
  justifyContent: "space-between",
};

const TitleIcon = ({
  title,
  iconURL,
  iconStyles,
}: {
  title: string;
  iconURL: string;
  iconStyles?: StyleProps;
}) => {
  return (
    <Flex gap={2}>
      <CustomImage src={iconURL} alt={title} w={4} h={"auto"} {...iconStyles} />
      <Text fontWeight={700}>{title}</Text>
    </Flex>
  );
};

const SectionSelectAll = ({
  handleClick,
  selectedAll,
  label,
}: {
  handleClick: Function;
  selectedAll: boolean;
  label: string;
}) => {
  return (
    <Button
      onClick={() => handleClick()}
      bg="white"
      size="sm"
      leftIcon={<Icon as={!selectedAll ? BiPlusCircle : BiCheckCircle} />}
      variant="outline"
      rounded={"full"}
      border={"none"}
      colorScheme={"gray"}
      pr={0}
      _hover={{ bgColor: "#FFF" }}
      _focus={{ bgColor: "#FFF" }}
    >
      {label}
    </Button>
  );
};

const AccordionItemButton = ({
  name,
  handleClick,
  selectedAll,
  label,
}: {
  name: string;
  handleClick: Function;
  selectedAll: boolean;
  label: string;
  hiddenBorder?: boolean;
}) => {
  return (
    <Flex {...accordionItemStyle}>
      <AccordionButton
        _hover={{ bgColor: "#FFF", cursor: "pointer" }}
        px={0}
        flex={1}
        gap={0}
      >
        <AccordionIcon />
        <Text ml={1} fontWeight={700}>
          {name}
        </Text>
      </AccordionButton>
      <SectionSelectAll
        handleClick={handleClick}
        selectedAll={selectedAll}
        label={label}
      />
    </Flex>
  );
};

const AdvancedSearch: FC<{
  dateOptions?: any;
  placeOptions?: any;
  audienceOptions?: any;
  categoryOptions?: any;
  isOpen: boolean;
  onClose: () => void;
}> = ({
  dateOptions,
  placeOptions,
  audienceOptions,
  categoryOptions,
  isOpen,
  onClose,
}) => {
  const [criteria, setCriteria] = useState<CriteriaState>(initState);
  const t = useTranslation();
  const navigate = useLocaleNavigate();
  let where: ModelTypes["Event_where"] = {};

  // const [
  //   fetchPastEvents,
  //   {
  //     data: { GetFilterPastEvents } = {
  //       GetFilterPastEvents: { totalDocs: 0, totalPage: 0 },
  //     },
  //     loading: pastEventsLoading,
  //   },
  // ] = useLazyQuery(GetFilterPastEventsQuery, {
  //   client: apolloClient,
  //   variables: {
  //     where: {
  //       ...where,
  //       region: {
  //         equals: region as Event_region_Input,
  //       },
  //     },
  //     locale: locale as LocaleInputType,
  //   },
  // });

  // const [
  //   fetchEvents,
  //   {
  //     data: { GetFilterEvents } = {
  //       GetFilterEvents: { totalDocs: 0, totalPage: 0 },
  //     },
  //     loading,
  //   },
  // ] = useLazyQuery(GetFilterEventsQuery, {
  //   client: apolloClient,
  //   variables: {
  //     where: {
  //       ...where,
  //       region: {
  //         equals: region as Event_region_Input,
  //       },
  //     },
  //     locale: locale as LocaleInputType,
  //   },
  // });

  const handleSelectAll = (key: string, value: string[]): void => {
    const mergedArray = criteria[key].concat(value);
    const uniqueArray = Array.from(new Set(mergedArray)); // Remove duplicates using Set

    setCriteria({
      ...criteria,
      [key]: uniqueArray,
    });
  };

  const handleResetAll = (key: string, array: string[]): void => {
    const uniqueArray = criteria[key].filter(
      (id: string) => !array.includes(id)
    );

    setCriteria({
      ...criteria,
      [key]: uniqueArray,
    });
  };

  const TopRow: FC<{
    onClose: () => void;
  }> = useCallback(
    ({ onClose }) => {
      return (
        <Flex
          direction={"row"}
          w="100%"
          pt={12}
          alignItems={"center"}
          pos={"relative"}
        >
          <Flex
            gap={1}
            alignItems={"center"}
            pos={"absolute"}
            left={0}
            cursor={"pointer"}
            onClick={() => setCriteria(initState)}
            _hover={{ opacity: 0.8 }}
          >
            <CustomImage
              src="/assets/images/advancedSearch/reset.svg"
              alt="reset"
              w={4}
              h={"auto"}
            />
            <Text color="#FFF">{t("filters.reset")}</Text>
          </Flex>
          <Box flex={1} textAlign={"center"}>
            <Text fontWeight={700}>{t("filters.advancedSearch")}</Text>
          </Box>
          <CustomImage
            src="/assets/images/advancedSearch/close.svg"
            alt="reset"
            w={4}
            h={"auto"}
            pos={"absolute"}
            right={0}
            cursor={"pointer"}
            onClick={() => onClose()}
            _hover={{ opacity: 0.8 }}
          />
        </Flex>
      );
    },
    [isOpen]
  );

  const DateRangeComps: FC<{
    handleSelect?: Function;
    value?: string;
    name?: string;
  }> = useCallback(
    ({ handleSelect, name }) => {
      const { locale } = useParams();
      let dateRangeLocale;

      switch (locale) {
        case "zh":
          dateRangeLocale = zhTW;
          break;
        case "cn":
          dateRangeLocale = zhCN;
          break;
        default:
          dateRangeLocale = enUS;
      }

      const [customTimeValue, setCustomTimeValue] = useState([
        {
          startDate: new Date(),
          endDate: addDays(new Date(), 7),
          key: "selection",
        },
      ]);

      const handleDatePickerOnChange = (ranges: { selection: any }) => {
        const { selection } = ranges;
        const startDate = dayjs(selection?.startDate);
        const endDate = dayjs(selection?.endDate);

        setCriteria({
          ...criteria,
          time: toggleValue(
            criteria["time"],
            `${startDate?.format("YYYY-MM-DD")},${endDate?.format(
              "YYYY-MM-DD"
            )}`,
            false
          ),
        });

        setCustomTimeValue([selection]);
      };

      return (
        <Flex direction="column">
          <DateRange
            editableDateInputs={true}
            onChange={(ranges: any) => handleDatePickerOnChange(ranges)}
            moveRangeOnFirstSelection={false}
            ranges={customTimeValue}
            showMonthAndYearPickers={false}
            showDateDisplay={false}
            locale={dateRangeLocale}
            minDate={dayjs()?.toDate()}
          />

          {/* <Box alignSelf={"flex-end"}>
            <Button
              cursor={"pointer"}
              fontSize={["sm"]}
              onClick={() => {
                setCriteria({
                  ...criteria,
                  time: toggleValue(
                    criteria["time"],
                    `${dayjs(customTimeValue?.[0]?.startDate).format(
                      "YYYY-DD-MM"
                    )},${dayjs(customTimeValue?.[0]?.endDate).format(
                      "YYYY-DD-MM"
                    )}`,
                    false
                  ),
                });
              }}
            >
              {t("filters.confirm")}
            </Button>
          </Box> */}
        </Flex>
      );
    },
    [isOpen]
  );

  const FilterTitleRow = ({
    title,
    field,
    value,
    iconURL,
  }: {
    title: string;
    field: string;
    value: string[];
    iconURL?: string;
  }) => {
    const selectedAlls = criteria[field].length === value?.length;
    const handleClick = () => {
      if (!selectedAlls) {
        setCriteria({
          ...criteria,
          [field]: value,
        });
      } else {
        setCriteria({
          ...criteria,
          [field]: [],
        });
      }
    };

    return (
      <Flex direction={"row"} justifyContent={"space-between"} w="100%">
        <Box flex={1} textAlign={"left"}>
          <TitleIcon title={title} iconURL={iconURL ?? ""} />
        </Box>

        <SectionSelectAll
          handleClick={handleClick}
          selectedAll={selectedAlls}
          label={selectedAlls ? t("filters.reset") : t("filters.all")}
        />
      </Flex>
    );
  };

  const KeywordFilter = useCallback(() => {
    const t = useTranslation();
    const [inputValue, setInputValue] = useState<String>("");
    const triggerRef = useRef<HTMLInputElement | null>(null);
    const inputValueRef = useRef(inputValue);

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      const newValue = e.target.value;
      setInputValue(newValue);

      setCriteria({
        ...criteria,
        search: toggleValue(criteria["search"], newValue, false),
      });

      inputValueRef.current = newValue;
    };

    return (
      <InputGroup size="sm" maxW={"full"}>
        <InputLeftElement
          pointerEvents="none"
          children={<Icon as={AiOutlineSearch} />}
        />
        <Input
          onChange={handleInputChange}
          ref={triggerRef}
          borderRadius="md"
          focusBorderColor="orange.300"
          type="text"
          variant="outline"
          w="full"
          bgColor="#FFF"
          value={inputValue as string}
          placeholder={t("sidebar.keywords") as string}
        />
      </InputGroup>
    );
  }, []);

  const handleSubmit = () => {
    const level3Districts = getLevel3Districts(placeOptions);
    let URLParams = [];

    if (criteria["search"].length > 0) {
      URLParams = criteria["search"].reduce(function (
        accumulator: any[],
        currentValue: any
      ) {
        const getValue = categoryOptions?.find(
          (item: { id: any }) => item.id === currentValue
        );
        accumulator?.push(getValue?.value);
        return accumulator;
      }, []);

      criteria["category"] = URLParams;
    }

    if (criteria["category"].length > 0) {
      URLParams = criteria["category"].reduce(function (
        accumulator: any[],
        currentValue: any
      ) {
        const getValue = categoryOptions?.find(
          (item: { id: any }) => item.id === currentValue
        );
        accumulator?.push(getValue?.value);
        return accumulator;
      }, []);

      criteria["category"] = URLParams;
    }

    if (criteria["audience"].length > 0) {
      URLParams = criteria["audience"].reduce(function (
        accumulator: any[],
        currentValue: any
      ) {
        const getValue = audienceOptions?.find(
          (item: { id: any }) => item.id === currentValue
        );
        accumulator?.push(getValue?.value);
        return accumulator;
      }, []);

      criteria["audience"] = URLParams;
    }

    if (criteria["district"].length > 0) {
      URLParams = criteria["district"].reduce(function (
        accumulator: any[],
        currentValue: any
      ) {
        const getValue = level3Districts?.find(
          (item: { id: any }) => item.id === currentValue
        );
        accumulator?.push(getValue?.value);
        return accumulator;
      }, []);

      criteria["district"] = URLParams;
    }

    if (criteria["fee"]?.length === feeOptions?.length) {
      criteria["fee"] = [];
    }

    if (criteria["walkIn"]?.length === walkInOptions?.length) {
      criteria["walkIn"] = [];
    }

    if (criteria["time"].length > 0) {
      criteria["time"] = [convertStringToTimeString(criteria["time"][0])];
    }
    const urlParams = convertJsonToUrlParams(criteria);

    navigate(`/event${urlParams}`);
  };

  // useEffect(() => {
  //   const where: ModelTypes["Event_where"] = {};

  //   where.AND = [];

  //   /** audience */
  //   if (criteria["audience"].length > 0) {
  //     where.AND.push({
  //       criteria__audiences: {
  //         in: criteria["audience"],
  //       },
  //     });
  //   }

  //   /** category */
  //   if (criteria["category"].length > 0) {
  //     where.AND.push({
  //       criteria__categories: {
  //         in: criteria["category"],
  //       },
  //     });
  //   }

  //   /** district */
  //   if (criteria["district"].length > 0) {
  //     where.AND.push({
  //       sections__district: {
  //         in: criteria["district"],
  //       },
  //     });
  //   }

  //   /** keywords */
  //   if (criteria["search"].length > 0) {
  //     where.name = {
  //       like: criteria["search"]?.[0],
  //     };
  //   }

  //   /** dates */
  //   const dateValue = criteria["time"]?.[0];

  //   if (dateValue === "past") {
  //     const query = {
  //       ...where,
  //       status: {
  //         equals: "published",
  //       },
  //       region: {
  //         equals: region as Event_region_Input,
  //       },
  //       AND: [
  //         ...where?.AND,
  //         {
  //           sections__endDatetime: {
  //             less_than_equal: dayjs().startOf("day").toISOString(),
  //           },
  //         },
  //         {
  //           sections__toThisDay: {
  //             less_than_equal: dayjs().startOf("day").toISOString(),
  //           },
  //         },
  //       ],
  //     };

  //     fetchPastEvents({
  //       variables: {
  //         where: {
  //           ...query,
  //           region: {
  //             equals: region as Event_region_Input,
  //           },
  //         },
  //       },
  //       fetchPolicy: "network-only",
  //     });
  //   } else {
  //     let start, end;
  //     switch (dateValue) {
  //       case "today":
  //         start = dayjs().startOf("day").toISOString();
  //         end = dayjs().endOf("day").toISOString();
  //         break;
  //       case "tomorrow":
  //         start = dayjs().add(1, "day").startOf("day").toISOString();
  //         end = dayjs().add(1, "day").endOf("day").toISOString();
  //         break;
  //       case "this-weekend":
  //         start = dayjs().weekday(6).startOf("day").toISOString();
  //         end = dayjs().weekday(7).endOf("day").toISOString();
  //         break;
  //       default:
  //         where.AND.push({
  //           sections__endDatetime: {
  //             greater_than_equal: dayjs().startOf("day").toISOString(),
  //           },
  //         });
  //     }

  //     // "custom-date"
  //     if (dateValue?.includes(",")) {
  //       const splitInput = dateValue?.split(",");
  //       const startDate = splitInput[0]?.replace("custom-date-", "");
  //       const endDate = splitInput[1];

  //       where.AND.push({
  //         sections__startDatetime: {
  //           less_than_equal: dayjs(endDate)
  //             .tz("Asia/Hong_Kong")
  //             .endOf("day")
  //             .toISOString(),
  //         },
  //         sections__endDatetime: {
  //           greater_than_equal: dayjs(startDate)
  //             .tz("Asia/Hong_Kong")
  //             .startOf("day")
  //             .toISOString(),
  //         },
  //       });
  //     }

  //     if (
  //       dateValue === "today" ||
  //       dateValue === "tomorrow" ||
  //       dateValue === "this-weekend"
  //     ) {
  //       where.AND.push({
  //         sections__startDatetime: {
  //           less_than_equal: end,
  //         },
  //         sections__endDatetime: {
  //           greater_than_equal: start,
  //         },
  //       });
  //     }

  //     fetchEvents({
  //       variables: {
  //         where: {
  //           ...where,
  //           region: {
  //             equals: region as Event_region_Input,
  //           },
  //         },
  //       },
  //     });
  //   }
  // }, [criteria]);

  // const totalDisplayDocs =
  //   criteria["time"]?.[0] === "past"
  //     ? GetFilterPastEvents?.totalDocs
  //     : GetFilterEvents?.totalDocs;

  return (
    <Modal isOpen={isOpen} onClose={onClose} autoFocus={false}>
      <ModalOverlay />
      <ModalContent
        w="full"
        maxW={"600px"}
        borderRadius={"lg"}
        overflow={"hidden"}
        className="advancedSearchModal"
        mx={[2]}
      >
        <ModalBody p={0} borderRadius={"lg"}>
          <Box
            backgroundSize={"contain"}
            backgroundPosition={"top center"}
            backgroundRepeat={"no-repeat"}
            backgroundImage="url('/assets/images/home/advanced_search_background.svg')"
            bgColor={"brand.light-grey"}
            px={[4, 8, 12]}
            pb={[4, 8]}
          >
            <VStack spacing={6} alignItems={"flex-start"}>
              <TopRow onClose={onClose} />

              <Flex {...sectionStyle}>
                <KeywordFilter />
              </Flex>

              <Flex {...sectionStyle}>
                <TitleIcon
                  title={t("filters.time")}
                  iconURL="/assets/images/advancedSearch/calender.svg"
                />
                <Wrap>
                  {(dateOptions ?? []).map((date: any) => {
                    let selected = criteria["time"].includes(
                      date.value as string
                    );

                    if (
                      date.value === "custom-date" &&
                      criteria["time"].toString().includes(",")
                    ) {
                      selected = true;
                    }

                    return (
                      <FilterTag
                        selected={selected}
                        key={date.id}
                        onChange={() =>
                          setCriteria({
                            ...criteria,
                            time: toggleValue(
                              criteria["time"],
                              date.value === "custom-date"
                                ? `${dayjs(new Date()).format(
                                    "YYYY-DD-MM"
                                  )},${dayjs(addDays(new Date(), 7)).format(
                                    "YYYY-DD-MM"
                                  )}`
                                : date.value,
                              false
                            ),
                          })
                        }
                        value={date.value as string}
                        label={date.name}
                        rounded={"md"}
                      />
                    );
                  })}
                </Wrap>
                {criteria["time"].toString().includes(",") && (
                  <DateRangeComps />
                )}
              </Flex>

              <Flex {...sectionStyle}>
                <TitleIcon
                  title={t("filters.location")}
                  iconURL="/assets/images/advancedSearch/pin.svg"
                />

                <Box>
                  <Accordion defaultIndex={[0, 1, 2]} allowMultiple>
                    {(placeOptions ?? []).map(
                      (_district: any, index: number) => {
                        const allDistricts =
                          _district?.children?.map(
                            ({ id }: { id: string }) => id
                          ) ?? [];

                        const selectedAll = allDistricts?.every(
                          (item: string) => criteria["district"]?.includes(item)
                        );

                        const handleClick = () => {
                          const selectedIds = (_district?.children ?? []).map(
                            ({ id }: { id: string }) => id
                          );

                          if (!selectedAll) {
                            handleSelectAll("district", selectedIds);
                          } else {
                            handleResetAll("district", selectedIds);
                          }
                        };
                        return (
                          <Box key={_district.id}>
                            <AccordionItem p={0} border={0}>
                              <AccordionItemButton
                                name={_district.name}
                                handleClick={handleClick}
                                selectedAll={selectedAll}
                                label={
                                  selectedAll
                                    ? t("filters.reset")
                                    : t("filters.all")
                                }
                              />
                              <AccordionPanel px={0}>
                                <Wrap>
                                  {(_district?.children ?? []).map(
                                    (__district: any) => {
                                      const selected = criteria[
                                        "district"
                                      ].includes(__district.id as string);
                                      return (
                                        <FilterTag
                                          selected={selected}
                                          key={__district.id}
                                          onChange={() =>
                                            setCriteria({
                                              ...criteria,
                                              district: toggleValue(
                                                criteria["district"],
                                                __district.id
                                              ),
                                            })
                                          }
                                          value={__district.id as string}
                                          label={__district.name}
                                          rounded={"md"}
                                        />
                                      );
                                    }
                                  )}
                                </Wrap>
                              </AccordionPanel>
                            </AccordionItem>
                            {placeOptions?.length !== index + 1 && (
                              <Divider p={0} mb={4} />
                            )}
                          </Box>
                        );
                      }
                    )}
                  </Accordion>
                </Box>
              </Flex>

              <Flex {...sectionStyle}>
                <FilterTitleRow
                  title={t("filters.character")}
                  field={"audience"}
                  value={audienceOptions?.map(({ id }: { id: string }) => id)}
                  iconURL="/assets/images/advancedSearch/audience.svg"
                />
                <Wrap>
                  {(audienceOptions ?? []).map((date: any) => {
                    const selected = criteria["audience"].includes(
                      date.id as string
                    );
                    return (
                      <FilterTag
                        selected={selected}
                        key={date.id}
                        onChange={() =>
                          setCriteria({
                            ...criteria,
                            audience: toggleValue(
                              criteria["audience"],
                              date.id
                            ),
                          })
                        }
                        value={date.id as string}
                        label={date.name}
                        rounded={"md"}
                      />
                    );
                  })}
                </Wrap>
                <Box></Box>
              </Flex>

              <Flex {...sectionStyle}>
                <FilterTitleRow
                  title={t("filters.type")}
                  field={"category"}
                  value={categoryOptions?.map(({ id }: { id: string }) => id)}
                  iconURL="/assets/images/advancedSearch/category.svg"
                />
                <Wrap>
                  {(categoryOptions ?? []).map((data: any) => {
                    const selected = criteria["category"].includes(
                      data.id as string
                    );
                    return (
                      <FilterTag
                        selected={selected}
                        key={data.id}
                        onChange={() =>
                          setCriteria({
                            ...criteria,
                            category: toggleValue(
                              criteria["category"],
                              data.id
                            ),
                          })
                        }
                        value={data.id as string}
                        label={data.name}
                        rounded={"md"}
                      />
                    );
                  })}
                </Wrap>
              </Flex>

              <Flex {...sectionStyle}>
                <TitleIcon
                  title={t("filters.feeEvent")}
                  iconURL="/assets/images/advancedSearch/fee.svg"
                />
                <Wrap>
                  {(feeOptions ?? []).map((date: any) => {
                    const selected = criteria["fee"].includes(
                      date.value as string
                    );
                    return (
                      <FilterTag
                        selected={selected}
                        key={date.id}
                        onChange={() =>
                          setCriteria({
                            ...criteria,
                            fee: toggleValue(criteria["fee"], date.value),
                          })
                        }
                        value={date.value as string}
                        label={date.name}
                        rounded={"md"}
                      />
                    );
                  })}
                </Wrap>
              </Flex>

              <Flex {...sectionStyle}>
                <TitleIcon
                  title={t("filters.joinMethod")}
                  iconURL="/assets/images/advancedSearch/joinMethod.svg"
                />
                <Wrap>
                  {(walkInOptions ?? []).map((date: any) => {
                    const selected = criteria["walkIn"].includes(
                      date.value as string
                    );
                    return (
                      <FilterTag
                        selected={selected}
                        key={date.id}
                        onChange={() =>
                          setCriteria({
                            ...criteria,
                            walkIn: toggleValue(criteria["walkIn"], date.value),
                          })
                        }
                        value={date.value as string}
                        label={date.name}
                        rounded={"md"}
                      />
                    );
                  })}
                </Wrap>
              </Flex>
            </VStack>
          </Box>
          <Box py={4} mx={[3]} bgColor={"#FFF"} textAlign={"center"}>
            <Button
              w="full"
              rounded={"full"}
              maxW={"500px"}
              mx={"auto"}
              onClick={handleSubmit}
              // isLoading={loading || pastEventsLoading}
              // isDisabled={!checkCondition(criteria)}
            >
              {/* {injectParam(t(`event.totalDocs`) as string, {
                  totalDocs:
                    (totalDisplayDocs as number) > 0
                      ? totalDisplayDocs?.toLocaleString()
                      : `0`,
                })} */}
              {t(`event.searchNow`)}
            </Button>
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default AdvancedSearch;
